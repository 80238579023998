import React, { useState } from 'react';
import './AI.css';
import aiImage from '../assets/img/AI_Home.jpg';
import aiFeature1 from '../assets/img/AI in Business.jpg';
import aiFeature2 from '../assets/img/AI in Industry.jpg';
import aiFeature3 from '../assets/img/AI Innovations.png';
import aiIcon1 from '../assets/icons/Predictive Analytics.png';
import aiIcon2 from '../assets/icons/Machine Learning.png';
import aiIcon3 from '../assets/icons/Automation.png';

const AI = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleLearnMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="ai-container">
            <div className="parallax-background"></div>

            <div className="ai-content">
                <h2 className="section-title1">Artificial Intelligence</h2>
                <p className="section-text">
                    Discover the power of AI. From predictive analytics to automation, our solutions integrate cutting-edge technology to enhance your business processes.
                </p>

                {!isExpanded && (
                    <div className="collapsed-extras">
                        <div className="ai-feature-cards">
                            <div className="ai-feature-card">
                                <img src={aiIcon1} alt="Predictive Analytics" className="ai-icon" />
                                <h3>Predictive Analytics</h3>
                                <p>Harness data to predict outcomes and make better business decisions.</p>
                            </div>
                            <div className="ai-feature-card">
                                <img src={aiIcon2} alt="Machine Learning" className="ai-icon" />
                                <h3>Machine Learning</h3>
                                <p>Automate learning from data without human intervention.</p>
                            </div>
                            <div className="ai-feature-card">
                                <img src={aiIcon3} alt="Automation" className="ai-icon" />
                                <h3>AI-driven Automation</h3>
                                <p>Reduce manual processes and improve efficiency with AI automation.</p>
                            </div>
                        </div>

                        <div className="image-carousel">
                            <img src={aiFeature1} alt="AI in Business" className="carousel-image" />
                            <img src={aiFeature2} alt="AI in Industry" className="carousel-image" />
                            <img src={aiFeature3} alt="AI Innovations" className="carousel-image" />
                        </div>
                    </div>
                )}

                <button onClick={handleLearnMore} className="cta-button">
                    {isExpanded ? 'Show Less' : 'Learn More'}
                </button>

                {isExpanded && (
                    <div className="expanded-content">
                        <p className="section-text">
                            Our AI solutions transform industries through innovative use cases. Here are some areas where AI is making a big impact:
                        </p>
                        <div className="ai-use-cases">
                            <div className="use-case">
                                <h4>Healthcare</h4>
                                <p>AI helps doctors diagnose diseases faster and with more accuracy.</p>
                            </div>
                            <div className="use-case">
                                <h4>Finance</h4>
                                <p>AI-driven algorithms detect fraud and manage large datasets in real-time.</p>
                            </div>
                            <div className="use-case">
                                <h4>Retail</h4>
                                <p>AI personalizes shopping experiences, recommending products tailored to customers.</p>
                            </div>
                        </div>

                        <div className="ai-image-grid">
                            <img src={aiFeature1} alt="AI Use in Healthcare" className="grid-image" />
                            <img src={aiFeature2} alt="AI in Finance" className="grid-image" />
                            <img src={aiFeature3} alt="AI in Retail" className="grid-image" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AI;
