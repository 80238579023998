import React from 'react';
import './Overview.css';  // Link to the CSS file

const Overview = () => {
    return (
        <div className="overview-container">
            <div className="overview-header">
                <h2 className="section-overview-title">Overview</h2>
            </div>
            <div className="overview-content">
            
            <p className="overview-subtitle">
                    <i>Excavate Research is a leader in Market Research and Business Intelligence.</i>
                </p>
                <p className="overview-paragraph">
                    In early 2010, a group of experienced market research professionals came together to pursue their passion for understanding the mind of the consumer. This is when Excavate Research was born, and today, it is one of the most innovative market research companies in India.
                </p>
                
                <div className="services-section">
                    <h3 className="section-overview-title">Our Core Services</h3>
                    <ul className="overview-services-list">
                        <li>Survey Programming</li>
                        <li>Data Collection</li>
                        <li>Open End Coding</li>
                        <li>Data Tabulation</li>
                        <li>Data Analysis</li>
                        <li>Online/Offline Dashboards Development</li>
                        <li>Monitoring Financial KPIs via real-time dashboards</li>
                        <li>Competitor Landscaping</li>
                        <li>Business Optimization & Streamlining</li>
                        <li>Investor Pitch Creation for Fundraising</li>
                        <li>Investment Trend & Future Outlook</li>
                        <li>Financial and Operational Performance Analysis</li>
                        <li>Business Plans & Financial Modeling</li>
                    </ul>
                </div>

                <div className="overview-conclusion">
                    <h3 className="section-overview-title">Innovations and Tools</h3>
                    <p className="overview-paragraph">
                        Excavate has launched Tablet Surveys, Automated PPTs, and Compliance Management tools. We offer customized development based on user requirements and consulting for Compliance Management.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Overview;
