import React, { useState } from 'react';
import './DataAnalysis.css'; // Link to CSS file
import dataAnalysisImage from '../assets/img/data visualization.jpg'; // Importing images
import dataAnalysisImage2 from '../assets/img/Data Analyst Career.jpg';
import dataAnalysisImage3 from '../assets/img/customizable report.jpg';
import excelIcon from '../assets/img/excelicon.png'; // Importing Excel icon
import powerPointIcon from '../assets/img/ppticon.png'; // Importing PowerPoint icon

const DataAnalysis = () => {
    const [showExpanded, setShowExpanded] = useState(false);

    const handleLearnMoreClick = () => {
        setShowExpanded(!showExpanded);
    };

    return (
        <div className="data-analysis-container">
            <div className="data-analysis-header">
                <h2 className="header-title">Quick and Easy Survey Analysis</h2>
                <p className="header-subtitle">
                    Get actionable insights from your survey data effortlessly with our advanced analytics tools.
                </p>
            </div>
            {/* Data Analysis Overview */}
            <div className="data-analysis-overview">
                <h2>Data Analysis Overview</h2>
                <p>
                    Excavate Research harnesses machine learning and deep learning to offer comprehensive data analysis solutions, from TURF and Regression to advanced predictive modeling. Our systems can analyze vast data sets quickly and accurately, providing actionable insights.
                </p>
            </div>
            {/* Our Capabilities with Cards */}
            <div className="data-analysis-capabilities">
                <h2 className="section-title">Our Capabilities:</h2>
                <div className="capabilities-cards">
                    <div className="capability-card">
                        <h3>AI and ML-Driven Analysis</h3>
                        <p>Deploy machine learning algorithms to uncover hidden trends and correlations.</p>
                    </div>
                    <div className="capability-card">
                        <h3>Predictive Modeling</h3>
                        <p>Use deep learning models to predict future outcomes based on historical data.</p>
                    </div>
                    <div className="capability-card">
                        <h3>Fast and Accurate Results</h3>
                        <p>Our AI algorithms analyze data faster and more accurately than traditional methods, ensuring high-quality insights at reduced costs.</p>
                    </div>
                </div>
            </div>

                {/* Default Content */}
                <button className="learn-more-button" onClick={handleLearnMoreClick}>
                    {showExpanded ? 'Show Less' : 'Learn More'}
                </button>

                {/* Expanded Explanation */}
                {showExpanded && (
                    <div className={`expanded-explanation ${showExpanded ? 'show' : 'hide'}`}>
                        <h2>Expanded Explanation</h2>
                        <p>
                            With advanced data analysis using AI, machine learning, and deep learning, Excavate can go beyond basic analytics to provide actionable insights. We apply predictive analytics, clustering, and segmentation models to identify patterns in large datasets. This includes TURF analysis, regression modeling, and correlation analysis, offering deep insights into consumer behavior, product performance, and more.
                        </p>
                        <h3>Case Study:</h3>
                        <p>
                            A financial services firm needed to predict customer churn using its historical survey data. Using Excavate's machine learning models, the company was able to identify key factors driving churn, allowing for targeted interventions. The predictive model achieved an accuracy of 92%, reducing churn by 20% in just six months, resulting in millions of dollars in customer retention savings.
                        </p>
                    </div>
                )}
            <div className="data-analysis-content">
                <h2 className="section-title">Why Choose Our Analysis Tools?</h2>

                <div className="image-text-block">
                    <img src={dataAnalysisImage2} alt="Data Analysis 1" className="content-image left" />
                    <div className="text-block">
                        <h3>Automated Data Cleaning</h3>
                        <p>
                            Our automated data cleaning feature simplifies the process of preparing your data for analysis. It intelligently identifies and corrects errors, such as missing values, duplicate records, and inconsistencies, ensuring that your dataset is accurate and reliable. This means you can focus more on gaining insights rather than spending time on manual data cleaning tasks. With just a few clicks, you can clean and preprocess your data to meet the highest standards of quality.
                        </p>
                    </div>
                </div>

                <div className="image-text-block">
                    <div className="text-block">
                        <h3>Advanced Data Visualization</h3>
                        <p>
                            Unlock deeper insights with our advanced data visualization tools. Our platform offers a range of sophisticated chart types and graph options to help you present your data in a meaningful way. From heatmaps and scatter plots to advanced trend lines and histograms, our visualizations make complex data more accessible and understandable. Customize your visuals to highlight key patterns, compare datasets, and make your findings more compelling. Perfect for data analysts who need to convey insights clearly and effectively to stakeholders.
                        </p>
                    </div>
                    <img src={dataAnalysisImage} alt="Data Analysis 2" className="content-image right" />
                </div>

                <div className="image-text-block">
                    <img src={dataAnalysisImage3} alt="Data Analysis 3" className="content-image left" />
                    <div className="text-block">
                        <h3>Customizable Reports</h3>
                        <p>
                            Create tailored reports that fit your specific needs with our customizable reporting tools. Our platform allows you to select the data you want to include, choose from various report formats, and customize the layout and design. Whether you need detailed analysis, executive summaries, or anything in between, you can generate professional reports that effectively communicate your findings. Save time and ensure your reports meet your exact requirements with our easy-to-use customization options.
                        </p>
                    </div>
                </div>

                <div className="integration-section">
                    <h2 className="section-title">Seamless Integration with Excel and PowerPoint</h2>
                    <div className="integration-points">
                        <div className="integration-point">
                            <img src={excelIcon} alt="Excel Integration" className="integration-image" />
                            <h3>Excel Integration</h3>
                            <p>
                                Easily export your analysis results to Excel for further manipulation and detailed examination. Our tools provide seamless data export functionality, allowing you to work with your data directly in Excel.
                            </p>
                        </div>
                        <div className="integration-point">
                            <img src={powerPointIcon} alt="PowerPoint Integration" className="integration-image" />
                            <h3>PowerPoint Integration</h3>
                            <p>
                                Create professional presentations directly from your analysis results. With our integration, you can effortlessly transfer charts and reports to PowerPoint, making it easy to present your findings to stakeholders.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DataAnalysis;
